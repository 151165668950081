import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Heading from "../common/heading/Heading";
import "./ServicesCard.css";

const Services = () => {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          "https://test-back.ttprogress.com/service/service/"
        );
        const data = await response.json();
        // Ограничиваем количество услуг до 4
        const limitedData = data.slice(0, 3);
        setServices(limitedData);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <>
      <Heading subtitle={t("SERVICES")} />
      <div className="services">
        {services.map((service) => (
          <Link
            
            to={`/service/${service.id}`}
            key={service.id}
          >
            <div className="service-cards" style={{color: "black"}}>
              <div className="service-image-container">
                <img
                  src={service.img}
                  alt={service[`name_${i18n.language}`]}
                  className="service-image"
                />
              </div>
              <div className="text-content">
                <h3 className="service-title">
                  {service[`name_${i18n.language}`]}
                </h3>
                <a href="" className="service-link service-btn">
                {t("MORE")}
                </a>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Services;
