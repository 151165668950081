import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { reviews } from './reviewsData';
import Heading from '../common/heading/Heading';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import './Testimonials.css';
import { t } from 'i18next';


const HighlightText = ({ text }) => {
    // Регулярное выражение для поиска слов
    const regex = /(consultation|консультацию|konsultatsiya)/gi;
  
    // Замена найденных слов на те же слова, но обернутые в span с оранжевым цветом
    const highlightedText = text.replace(regex, (match) => `<span style="color: orange;">${match}</span>`);
  
    // Преобразование строки с HTML-тегами в JSX
    return <>{parse(highlightedText)}</>;
  };

const Testimonials = ({ currentPage }) => {
  const filteredReviews = reviews.filter(review => review.pages.includes(currentPage));

  const { t } = useTranslation();

  const subtitle = t('TESTIMONIALS');
  const title = t('OUR_SUCCESSFUL_STUDENTS');

  return (
    <section className="testimonial-container-test">
      <div className="title-test">
        <Heading subtitle={t('TESTIMONIALS')} title={t('OUR_SUCCESSFUL_STUDENTS')} />
      </div>

      <div className="slider-container-test">
        <blockquote>
          {/* <img className="top-quote quote" src={Quote} alt="quote" />
          <img className="bottom-quote quote" src={Quote} alt="quote" /> */}
        </blockquote>

        <Splide
          options={{
            perPage: 1,
            autoplay: true,
            speed: 1000,
            rewind: true,
            rewindByDrag: true,
          }}
        >
          {filteredReviews.map((review) => (
            <SplideSlide key={review.id}>
              <div className="image-container-test">
                <img className="image-test" src={review.image} alt={review.name} />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default Testimonials;
