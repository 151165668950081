import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Awrapper = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://test-back.ttprogress.com/main/stats/');
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className='awrapper'>
      <div className='container grid'>
        {data.map((val, index) => (
          <div key={index} className='box flex'>
            <div className='img'>
              <img src={val.img} alt='' />
            </div>
            <div className='text'>
              <h1>{val[`title_top_${i18n.language}`]}</h1>
              <h3>{t(val[`title_bottom_${i18n.language}`])}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Awrapper;
