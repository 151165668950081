import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './courses.css';
import Heading from '../common/heading/Heading';
import { Link } from 'react-router-dom';

const Services = () => {
  const [services, setServices] = useState([]);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    fetch('https://test-back.ttprogress.com/service/service/')
      .then(response => response.json())
      .then(data => {
        // Sort the data so that services with 'vip', 'Vip', or 'VIP' in their name appear first
        const sortedData = data.sort((a, b) => {
          const nameA = a[`name_${i18n.language}`]?.toLowerCase();
          const nameB = b[`name_${i18n.language}`]?.toLowerCase();
          
          const isVipA = nameA?.includes('vip');
          const isVipB = nameB?.includes('vip');

          return isVipA === isVipB ? 0 : isVipA ? -1 : 1;
        });
        setServices(sortedData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [i18n.language]);

  return (
    <>
      <Heading subtitle={t("SERVICES")} />
      <div className="services">
        {services.map((service) => (
          <Link 
            
            to={`/service/${service.id}`} 
            key={service.id}
          >
            <div className="service-cards" style={{color: "black"}}>
              <div className="service-image-container">
                <img src={service.img} alt={service[`name_${i18n.language}`]} className="service-image" />
              </div>
              <div className="text-content">
                <h3 className="service-title">{service[`name_${i18n.language}`]}</h3>
                <a href="" className="service-link service-btn">
                {t("MORE")}
                </a>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Services;
