import React, { useState, useEffect } from "react";
import Heading from "../common/heading/Heading";
import "./about.css";
import { useTranslation } from "react-i18next";
import defaultImg from "../../assets/2024-03-29 16.24 1.png"; // Используйте заглушку, если нет изображения

const AboutCard = () => {
  const { t, i18n } = useTranslation();
  const [aboutData, setAboutData] = useState(null);
  const [mentorData, setMentorData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch("https://test-back.ttprogress.com/main/about/");
        const data = await response.json();
        console.log("About data:", data); // Логируем данные
        setAboutData(data[0]);
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };

    const fetchMentorData = async () => {
      try {
        const response = await fetch("https://test-back.ttprogress.com/main/aboutmentor/");
        const data = await response.json();
        console.log("Mentor data:", data); // Логируем данные
        setMentorData(data[0]);
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      }
    };

    const fetchData = async () => {
      await fetchAboutData();
      await fetchMentorData();
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!aboutData || !mentorData) {
    return <div>Error loading data.</div>;
  }

  const getImage = (img) => img || defaultImg;

  return (
    <>
      <section className="aboutHome about">
        <div className="container flexSB">
          <div className="right row">
            <Heading subtitle={t("ABOUT_US")} title={aboutData[`title_1_${i18n.language}`]} />
            <div className="items">
              {/* <p style={{ fontWeight: "bold" }}>{aboutData[`title_1_${i18n.language}`]}</p> */}
              <div style={{ fontSize: "14.5px" }}>{aboutData[`text_1_${i18n.language}`]}</div>
              <br />
              <p style={{ fontWeight: "bold" }}>{aboutData[`title_2_${i18n.language}`]}</p>
              <div style={{ fontSize: "14.5px" }}>{aboutData[`text_2_${i18n.language}`]}</div>
              <br />
              <p style={{ fontWeight: "bold" }}>{aboutData[`title_3_${i18n.language}`]}</p>
              <div style={{ fontSize: "14.5px" }}>{aboutData[`text_3_${i18n.language}`]}</div>
              <br />
            </div>
          </div>
          <div className="left row">
            <img src={getImage(aboutData.img)} alt="About Us" className="first-image-about" />
          </div>
        </div>
        <div className="container flexSB about-mentors">
          <div className="left row bottom-image">
            <img src={getImage(mentorData.img)} alt="Mentors" />
          </div>
          <div className="right row bottom-text">
            <Heading title={mentorData[`title_${i18n.language}`]} />
            <div className="items">
              <div style={{ fontSize: "14.5px" }}>{mentorData[`text_1_${i18n.language}`]}</div>
              <br />
              <div style={{ fontSize: "14.5px" }}>{mentorData[`text_2_${i18n.language}`]}</div>
              <br />
              <div style={{ fontSize: "14.5px" }}>{mentorData[`text_3_${i18n.language}`]}</div>
              <br />
            </div>
          </div>
          <div className="left row top-image">
            <img src={getImage(mentorData.img)} alt="Mentors" />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCard;
