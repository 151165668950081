import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Team from "./components/team/Team";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import Loader from "./components/Loader/Loader";
import { useState, useEffect } from "react";
import VideoBlog from "./components/VideoBlog/VideoBlog";
import Testimonal from "./components/home/testimonal/Testimonal";
import Testimonials from "./components/Testimonials/Testimonials";

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null); // State to store fetched data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://tt.back-texnoprom.uz/"); // Replace with your API endpoint
        const result = await response.json();
        setData(result); // Save fetched data to state
        setLoading(false); // Update loading state
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Update loading state even if there's an error
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" render={() => <Home data={data} />} /> {/* Pass data to Home component */}
            <Route exact path="/about" render={() => <About data={data} />} /> {/* Pass data to About component */}
            <Route exact path="/services" render={() => <CourseHome data={data} />} /> {/* Pass data to CourseHome component */}
            {/* <Route exact path='/team' component={Team} /> */}
            {/* <Route exact path='/pricing' component={Pricing} /> */}
            {/* <Route exact path='/journal' component={Blog} /> */}
            <Route exact path="/contact" render={() => <Contact data={data} />} /> {/* Pass data to Contact component */}
            <Route exact path="/service/:id" render={(props) => <SingleProduct {...props} data={data} />} /> {/* Pass data to SingleProduct component */}
          </Switch>
          {/* <Testimonal/> */}
          <Testimonials currentPage={"home"}/>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
