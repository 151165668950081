import React from "react";
import "./Form.css"; // Make sure to create a Form.css file in the same directory
import IMG from '../../../assets/form-ava.webp';
import Heading from "../heading/Heading";
import { useTranslation } from "react-i18next";
import VideoBlog from "../../VideoBlog/VideoBlog";

const Form = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Heading subtitle={t("Form_subtitle")} title={t("Form_title")} />
      {/* <div className="form-container"> */}
        {/* <div className="profile-section">
          <img src={IMG} alt="Ms. Tursunoy" className="profile-image" />
          <div className="naming">
            <h2 style={{ color: "white" }}>Ms. Tursunoy</h2>
            <p>{t("SEO_CONSULTANT")}</p>
            <p style={{ color: "white" }}>{t("LEAVE_YOUR_CONTACTS")}</p>
          </div>
        </div> */}
        <div className="contact-form">
          <iframe
            src="https://forms.amocrm.ru/rtdclxm"
            title="AmoCRM Form"
            style={{marginTop: '-90px'}}
            frameBorder="0"
            scrolling="no"
            overflow="hidden"
            locale="en"
          ></iframe>
        </div>
      {/* </div> */}
      <VideoBlog/>
    </>
  );
};

export default Form;
