import React from 'react';
import { useTranslation } from 'react-i18next';
import './VideoBlog.css';

const VideoBlog = () => {
  const { i18n } = useTranslation();
  
  const videoLinks = {
    uz: "https://www.youtube.com/embed/oxlovzBokR4?si=oTG5fgKSZ-pus2kv",
    ru: "https://www.youtube.com/embed/WOoh4yv4cIU?si=ZiVSs_kCpadqpEh6",
    en: "https://www.youtube.com/embed/WOoh4yv4cIU?si=ZiVSs_kCpadqpEh6"
  };

  const videoSrc = videoLinks[i18n.language] || videoLinks.en; // Use English video as default

  return (
    <div className="vidMom">
      <iframe 
          className="ifarmeVideo" 
          src={videoSrc}  
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen
      />
    </div>
  );
}

export default VideoBlog;
