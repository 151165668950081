import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./footer.css";
import Form from "./Form";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { t, i18n } = useTranslation();
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetch("https://test-back.ttprogress.com/main/contacts/")
      .then((response) => response.json())
      .then((data) => {
        setContacts(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [heroData, setHeroData] = useState({
    title_top: "",
    title_bottom: "",
    title_sub: ""
  });

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await fetch('https://test-back.ttprogress.com/main/hero/');
        const data = await response.json();
        const hero = data[0];

        setHeroData({
          title_top: hero[`title_top_${i18n.language}`],
          title_bottom: hero[`title_bottom_${i18n.language}`],
          title_sub: hero[`title_sub_${i18n.language}`]
        });
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    };

    fetchHeroData();
  }, [i18n.language]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {currentPath !== "/contact" && <Form className="footer-form" />}
      <footer>
        <div className="container padding space">
          <div className="box logo">
            <h1 style={{ color: "#F6B548" }}>T&T PROGRESS</h1>
            <p style={{ color: "#F6B548" }}>{heroData.title_bottom}</p>
            <p>{heroData.title_sub}</p>
            <div className="social-icons">
              <a href="https://t.me/ttprogress">
                <i className="fab fa-telegram icon"></i>
              </a>
              <a href="https://www.youtube.com/@tursunoyusmonova">
                <i className="fab fa-youtube icon"></i>
              </a>
              <a href="https://www.instagram.com/ttprogress/">
                <i className="fab fa-instagram icon"></i>
              </a>
            </div>
          </div>
          <div className="box link">
            <h3>{t("EXPLORE")}</h3>
            <ul>
              <li>
                <Link to="/about" onClick={scrollToTop}>{t("ABOUT_US_F")}</Link>
              </li>
              <li>
                <Link to="/services" onClick={scrollToTop}>{t("SERVICES_F")}</Link>
              </li>
              <li>
                <Link to="/contact" onClick={scrollToTop}>{t("CONTACT_US")}</Link>
              </li>
            </ul>
          </div>
          <div className="box last">
            <h3>{t("HAVE_A_QUESTIONS")}</h3>
            <ul>
              <li>
                <i className="fa fa-map" style={{color: "#F6B548"}}></i>
                <a href="https://yandex.uz/maps/-/CDbniA24" className="adress-text">Toshkent Shahar, Mirobod tumani, Turkiston ko’chasi, 12A. 100015</a>
              </li>
              {contacts.map((contact) => (
                <li key={contact.id}>
                  <i className="fa fa-phone" style={{color: "#F6B548"}}></i>
                  <a href={`tel:${contact.first_contact}`}>{contact.first_contact}</a>
                </li>
                
              ))}
              {contacts.map((contact) => (
                <>
                <li key={contact.id}>
                  <i className="fa fa-phone" style={{color: "#F6B548"}}></i>
                  <a href={`tel:${contact.second_contact}`}>{contact.second_contact}</a>
                </li>
                
                </>
                
              ))}
              <li>
                <i className="fa fa-paper-plane" style={{color: "#F6B548"}}></i>
                <a href="mailto:info@ttprogress.com">info@ttprogress.com</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p className="copyright">
          <a href="https://t.me/doppidev_uz/" style={{ textDecoration: "none", color: "white", textAlign: "center" }}>
            {t("COPYRIGHT")} ©2024 {t("ALL_RIGHTS_RESERVED")} | {t("MADE_WITH_LOVE")} by Doppi-Dev.
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
