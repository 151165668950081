import React, { useState, useEffect } from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import "./universityslider.css";
import Heading from "../common/heading/Heading";
import { useTranslation } from "react-i18next";

const UnivesitySlider = () => {
  const [showSlides, setShowSlides] = useState(8);
  const [logos, setLogos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSlides(2);
      } else {
        setShowSlides(7);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await fetch("https://test-back.ttprogress.com/main/univerlogos/");
        const data = await response.json();
        setLogos(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logos:", error);
        setLoading(false);
      }
    };

    fetchLogos();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Heading subtitle={t("OUR_ACHIEVEMENTS")} />
      <div className="slider-container2">
        <Carousel
          className="exampleCarousel1"
          show={showSlides}
          slide={1}
          swiping={true}
          autoSwipe={2000}
          leftArrow={false}
          rightArrow={false}
          responsive={true}
          infinite={true}
        >
          {logos.map((logo) => (
            <img src={logo.img} alt={logo[`name_${i18n.language}`]} key={logo.id} className="slide-image" />
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default UnivesitySlider;
