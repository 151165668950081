import React, { useState, useEffect } from 'react';
import { Carousel } from '3d-react-carousal';
import './slider.css'; 
import Heading from '../common/heading/Heading';

const Slider = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://test-back.ttprogress.com/main/certificates/')
      .then(response => response.json())
      .then(data => {
        const slideData = data.map(item => (
          <img key={item.id} src={item.img} alt={item.name_ru || item.name_en || item.name_uz} className='images-slider'/>
        ));
        setSlides(slideData);
        setLoading(false); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
     
        <div className="slider-container"> 
          <Carousel slides={slides} interval={3000} autoplay  className="slider-item"/>
        </div>
     
    </>
  );
}

export default Slider;
