import React from "react"
import "./about.css"
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"
import { useScrollLockX } from "../../hooks/useScrollLock"
import Slider from "../slider/Slider"

const About = () => {
  // useScrollLockX()
  return (
    <>
      {/* <Back title='About Us' /> */}
      <AboutCard />
      <Slider/>
    </>
  )
}

export default About
