import React, { useState, useEffect } from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [heroData, setHeroData] = useState({
    title_top: "",
    title_bottom: "",
    title_sub: ""
  });

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await fetch('https://test-back.ttprogress.com/main/hero/');
        const data = await response.json();
        const hero = data[0];

        setHeroData({
          title_top: hero[`title_top_${i18n.language}`],
          title_bottom: hero[`title_bottom_${i18n.language}`],
          title_sub: hero[`title_sub_${i18n.language}`]
        });
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    };

    fetchHeroData();
  }, [i18n.language]);

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row hero-content">
            <Heading
              subtitle={heroData.title_top}
              title={heroData.title_bottom}
            />
            <p style={{ paddingLeft: "0px" }} className="facepalm">
              {heroData.title_sub}
            </p>
            <div className="button" style={{ zIndex: "10000000", order: "10 !important" }}>
              {/* <button className='primary-btn'>
                {t('GET STARTED NOW')}<i className='fa fa-long-arrow-alt-right'></i>
              </button> */}
              {/* <Link to="/services">
                <button style={{ zIndex: "10000000" }}>
                  {t("VIEW COURSE")}
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;
