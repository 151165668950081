import React from "react";
import parse from "html-react-parser";

const HighlightText = ({ text }) => {
  if (typeof text !== 'string') return null;

  const regex = /(consultation|консультацию|konsultatsiya)/gi;
  const highlightedText = text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
  return <>{parse(highlightedText)}</>;
};

const Heading = ({ subtitle, title }) => {
  return (
    <>
      <div id='heading'>
        <h3><HighlightText text={subtitle} /></h3>
        <h1><HighlightText text={title} /></h1>
      </div>
    </>
  );
};

export default Heading;
