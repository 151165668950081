import IMG from "../../assets/Grey Minimal Customer Review Quote Instagram Post.png";
import IMG2 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/2.png";
import IMG3 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/3.png";
import IMG4 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/4.png";
import IMG5 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/5.png";
import IMG6 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/6.png";
import IMG7 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/7.png";
import IMG8 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/8.png";
import IMG9 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/9.png";
import IMG10 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/10.png";
import IMG11 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/11.png";
import IMG12 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/12.png";
import IMG13 from "../../assets/Grey Minimal Customer Review Quote Instagram Post/13.png";

export const reviews = [
  {
    id: 1,
    image: IMG,
    name: "John Doe",
    text: "I was extremely pleased with the quality of the product. It exceeded my expectations and provided great value for the price.",
    pages: ["home", "product"],
  },
  {
    id: 2,
    image: IMG2,
    name: "Jane Smith",
    text: "The customer service was excellent. They were responsive and helpful throughout the entire process, making it a smooth experience for me.",
    pages: ["home"],
  },
  {
    id: 3,
    image: IMG3,
    name: "Alex Johnson",
    text: "The attention to detail in their work is impressive. Every aspect of the project was handled with precision and care. I highly recommend their services.",
    pages: ["home"],
  },
  {
    id: 4,
    image: IMG4,
    name: "Emily Davis",
    text: "The team demonstrated a deep understanding of my requirements. They were able to capture the essence of my vision and deliver a product that exceeded my expectations.",
    pages: ["home", "about"],
  },
  {
    id: 5,
    image: IMG5,
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    pages: ["home"],
  },
  {
    id: 6,
    image: IMG6,
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    pages: ["home"],
  },
  {
    id: 7,
    image: IMG7,
    name: "John Doe",
    text: "I was extremely pleased with the quality of the product. It exceeded my expectations and provided great value for the price.",
    pages: ["home", "product"],
  },
  {
    id: 8,
    image: IMG8,
    name: "Jane Smith",
    text: "The customer service was excellent. They were responsive and helpful throughout the entire process, making it a smooth experience for me.",
    pages: ["home"],
  },
  {
    id: 9,
    image: IMG9,
    name: "Alex Johnson",
    text: "The attention to detail in their work is impressive. Every aspect of the project was handled with precision and care. I highly recommend their services.",
    pages: ["home"],
  },
  {
    id: 10,
    image: IMG10,
    name: "Emily Davis",
    text: "The team demonstrated a deep understanding of my requirements. They were able to capture the essence of my vision and deliver a product that exceeded my expectations.",
    pages: ["home", "about"],
  },
  {
    id: 11,
    image: IMG11,
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    pages: ["home"],
  },
  {
    id: 12,
    image: IMG12,
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    pages: ["home"],
  },
  {
    id: 13,
    image: IMG13,
    name: "David Miller",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    pages: ["home"],
  },
];
