import React from "react";
import logo from "../../../assets/logo.webp";
import { Link } from "react-router-dom";
import "./header.css";
import { useTranslation } from "react-i18next";

const Head = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [click2, setClick] = React.useState(false);

  const handleClick = () => setClick(!click2);
  const Close = () => setClick(false);

  return (
    <>
      <section className="head" style={{ backgroundColor: "#1C2120" }}>
        <div className="container flexSB">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>

          <div>
            <div
              className={click2 ? "main-container" : ""}
              onClick={() => Close()}
            />
            <nav className="navbar" onClick={(e) => e.stopPropagation()}>
              <div className="nav-container">
                <ul className={click2 ? "nav-menu active" : "nav-menu"}>
                  <li className="nav-item">
                    <Link
                      exact="true"
                      to="/"
                      activeclassname="active"
                      className="nav-links"
                      onClick={click2 ? handleClick : null}
                    >
                      {t("home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      exact="true"
                      to="/services"
                      activeclassname="active"
                      className="nav-links"
                      onClick={click2 ? handleClick : null}
                    >
                      {t("services")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      exact="true"
                      to="/about"
                      activeclassname="active"
                      className="nav-links"
                      onClick={click2 ? handleClick : null}
                    >
                      {t("about")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      exact="true"
                      to="/contact"
                      activeclassname="active"
                      className="nav-links"
                      onClick={click2 ? handleClick : null}
                    >
                      {t("contact")}
                    </Link>
                  </li>
                  {/* <li className="nav-item"> */}
                  <div className="language-container">
                      <i className="fa fa-globe language-icon"></i>
                      <select
                        className="language-select"
                        onChange={(e) => changeLanguage(e.target.value)}
                      >
                        <option value="ru">{t("russian")}</option>
                        <option value="en">{t("english")}</option>
                        <option value="uz">{t("uzbek")}</option>
                      </select>
                    </div>
                  {/* </li> */}
                </ul>
                <div className="nav-icon" onClick={handleClick}>
                  <i className={click2 ? "fa fa-times" : "fa fa-bars"}></i>
                </div>
              </div>
            </nav>
          </div>
          <div className="social">
            <a href="https://t.me/ttprogress">
              <i className="fab fa-telegram icon"></i>
            </a>
            <a href="https://www.youtube.com/@tursunoyusmonova">
              <i className="fab fa-youtube icon"></i>
            </a>
            <a href="https://www.instagram.com/ttprogress/">
              <i
                className="fab fa-instagram icon"
                style={{ cursor: "pointer" }}
              ></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
