import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import Back from "../common/back/Back";
import "./contact.css";
import Heading from "../common/heading/Heading";
import parse from "html-react-parser";
import { Padding } from "@mui/icons-material";


const HighlightText = ({ text }) => {
  const regex = /(consultation|консультацию|konsultatsiya)/gi;
  const highlightedText = text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
  return <>{parse(highlightedText)}</>;
};


const Contact = () => {
  // useScrollLockX()
  const { t, i18n } = useTranslation();
  const map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d330.025863498035!2d69.28408300736679!3d41.29146386262104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8bcde4ebc027%3A0x952a1b392a536679!2sPlatform%20Business%20Center!5e0!3m2!1sru!2sru!4v1723058236253!5m2!1sru!2sru";
  const [contacts, setContacts] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  useEffect(() => {
    fetch("https://test-back.ttprogress.com/main/contacts/")
      .then(response => response.json())
      .then(data => {
        setContacts(data);
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, subject, message } = formData;
    const YOUR_BOT_TOKEN = "6782654814:AAGPAO6tqJHaETbVJynAki5mzEssLRzjr0I";
    const YOUR_CHANNEL_ID = "-1002075066553";

    const messageText = `
    --Message from website--
      Name: ${name}
      Email: ${email}
      Subject: ${subject}
      Message: ${message}
    `;

    try {
      await axios.post(
        `https://api.telegram.org/bot${YOUR_BOT_TOKEN}/sendMessage`,
        {
          chat_id: YOUR_CHANNEL_ID,
          text: messageText
        }
      );
      // Clear the form
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: ""
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

   const subtitle = t('TESTIMONIALS');
  const title = t('OUR_SUCCESSFUL_STUDENTS');

  return (
    <>
      <Heading subtitle={t("CONTACT_US")}  />
      {/* <Back title={t("CONTACT_US")} /> */}

      <section className="contacts" style={{ paddingTop: "100px" }}>
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe className="map" src={map} title={t("MAP")} />
          </div>
          <div className="right row">
           
            <div className="items grid2 contact-item">
              <div className="box">
                <h4>{t("ADDRESS")}</h4>
                <p>Toshkent Shahar, Mirobod tumani, Turkiston ko’chasi, 12A. 100015</p>
              </div>
              <div className="box">
                <h4>{t("EMAIL")}</h4>
                <p>info@ttprogress.com</p>
              </div>
              {contacts.map(contact => (
              <div className="box" key={contact.id}>
                <h4>{t("PHONE")}</h4>
                <p>{contact.first_contact}</p>
                <p>{contact.second_contact}</p>
              </div>
              ))}
            </div>
            {/* <Heading subtitle={subtitle} title={title}  /> */}
            <Heading title={t("Form_title")} />
 
            <div className="contact-form-contact">
          <iframe
            src="https://forms.amocrm.ru/rtwzrtm"
            title="AmoCRM Form"
            style={{marginTop: '-80px'}}
            frameBorder="0"
            scrolling="no"
            overflow="hidden"
            locale="en"
          ></iframe>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
