import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import Heading from "../common/heading/Heading";
import "./singleproduct.css";
import Slider from "../slider/Slider";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const SingleProduct = (props) => {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState({});
  const [vipPlan, setVipPlan] = useState(null);
  const [standardPlan, setStandardPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vipPrice, setVipPrice] = useState(null);
  const [standardPrice, setStandardPrice] = useState(null);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  ScrollToTop();

  const transformDescription = (description) => {
    if (!description) return {};
    return description
      .split(";")
      .filter((item) => item.trim() !== "")
      .reduce((acc, item, index) => {
        acc[index + 1] = item.trim();
        return acc;
      }, {});
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { id } = props.match.params;

        // Получение данных о продукте из API
        const productResponse = await fetch(
          `https://test-back.ttprogress.com/service/getsingl/${id}/`
        );
        const productData = await productResponse.json();
        if (productData.length > 0) {
          setProduct(productData[0]);
          console.log("Fetched product data:", productData[0]);

          const standardPlanResponse = await fetch(
            `https://test-back.ttprogress.com/service/standart/${productData[0].id}/`
          );
          const standardPlanData = await standardPlanResponse.json();
          if (standardPlanData.length > 0) {
            const transformedStandardPlan = {
              ...standardPlanData[0],
              description_uz: transformDescription(
                standardPlanData[0].description_uz
              ),
              description_ru: transformDescription(
                standardPlanData[0].description_ru
              ),
              description_en: transformDescription(
                standardPlanData[0].description_en
              ),
            };
            setStandardPlan(transformedStandardPlan);
            console.log("Transformed Standard Plan:", transformedStandardPlan);
          } else {
            console.log("No standard plan data found.");
          }

          const vipPlanResponse = await fetch(
            `https://test-back.ttprogress.com/service/vip/${productData[0].id}/`
          );
          const vipPlanData = await vipPlanResponse.json();
          if (vipPlanData.length > 0) {
            const transformedVipPlan = {
              ...vipPlanData[0],
              description_uz: transformDescription(
                vipPlanData[0].description_uz
              ),
              description_ru: transformDescription(
                vipPlanData[0].description_ru
              ),
              description_en: transformDescription(
                vipPlanData[0].description_en
              ),
            };
            setVipPlan(transformedVipPlan);
            console.log("Transformed VIP Plan:", transformedVipPlan);
          } else {
            console.log("No VIP plan data found.");
          }
        } else {
          console.log("No product data found.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductData();
  }, [props.match.params]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const { id } = props.match.params;

        // Получение данных о продукте из API
        const productResponse = await fetch(
          `https://test-back.ttprogress.com/service/getsingl/${id}/`
        );
        const productData = await productResponse.json();
        if (productData.length > 0) {
          setProduct(productData[0]);

          // Получение цены для VIP услуги
          const vipPlanResponse = await fetch(
            `https://test-back.ttprogress.com/service/vip/${productData[0].id}/`
          );
          const vipPlanData = await vipPlanResponse.json();
          if (vipPlanData.length > 0) {
            // setVipPlan(vipPlanData[0]);
            setVipPrice(vipPlanData[0].price);
          }

          // Получение цены для стандартной услуги
          const standardPlanResponse = await fetch(
            `https://test-back.ttprogress.com/service/standart/${productData[0].id}/`
          );
          const standardPlanData = await standardPlanResponse.json();
          if (standardPlanData.length > 0) {
            // setStandardPlan(standardPlanData[0]);
            setStandardPrice(standardPlanData[0].price);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPrices();
  }, [props.match.params]);

  const addLineBreaks = (text) => {
    if (!text) return null;
    return text.split(";").map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  };

  // const addLineBreaks2 = (text) => {
  //   if (!text) return null;
  //   return Object.values(text).map((item, index) => (
  //     <span key={index}>
  //       • {item}
  //       <br />
  //       <br />
  //     </span>
  //   ));
  // };

  const normalizeText = (text) => {
    return text.replace(/[\s.,!]/g, "").toLowerCase();
  };

  const renderComparisonTable = () => {
    const vipFeatures = vipPlan?.[`description_${i18n.language}`] || {};
    const standardFeatures =
      standardPlan?.[`description_${i18n.language}`] || {};

    if (
      Object.keys(vipFeatures).length === 0 ||
      Object.keys(standardFeatures).length === 0
    ) {
      return null;
    }

    const matchedFeatures = [];
    const unmatchedFeatures = [];

    Object.entries(vipFeatures).forEach(([key, feature]) => {
      const isMatched = Object.values(standardFeatures).some(
        (standardFeature) =>
          normalizeText(standardFeature) === normalizeText(feature)
      );
      const featureEntry = (
        <tr key={key}>
          <td>{feature}</td>
          <td>{isMatched ? <CheckOutlined /> : <CloseOutlined />}</td>
          <td>
            <CheckOutlined />
          </td>
        </tr>
      );

      if (isMatched) {
        matchedFeatures.push(featureEntry);
      } else {
        unmatchedFeatures.push(featureEntry);
      }
    });

    return (
      <table className="comparison-table">
        <thead>
          <tr>
            <th>{t("Feature")}</th>
            <th>{t("Standard Plan")}</th>
            <th>{t("VIP Plan")}</th> {/* Swapped VIP and Standard */}
          </tr>
        </thead>
        <tbody>
          {matchedFeatures}
          {unmatchedFeatures}
        </tbody>
      </table>
    );
  };

  const productName = product[`name_${i18n.language}`] || "";
  const isVipProduct = /vip/i.test(productName);
  const isStandardProduct = /standart|стандартный|стандарт|standard/i.test(
    productName
  );

  return (
    <>
      <div className="single-product">
        <div className="container flexSB product-cont">
          <div className="left row image-product">
            <img
              src={product.img}
              alt=""
              style={{ maxHeight: "600px", objectFit: "cover" }}
              className="img-product"
            />
          </div>
          <div className="right row product1">
            {/* Получение названия продукта из API */}
            <Heading id="title-plan" title={product[`name_${i18n.language}`]} />
            <div className="items">
              <div className="product-description">
                {addLineBreaks(product[`description_${i18n.language}`])}
              </div>
            </div>
            {/* <div className="plan-price">
              <p style={{ fontWeight: "bold" }}>{t("PRICE")}:</p>
              {isVipProduct && <p>{vipPlan?.[`name_${i18n.language}`]} - {vipPrice}</p>}
              {isStandardProduct && <p>{standardPlan?.[`name_${i18n.language}`]} - {standardPrice}</p>}
              {!isVipProduct && !isStandardProduct && (
                <>
                  <p>{vipPlan?.[`name_${i18n.language}`]} - {vipPrice}</p>
                  <p>{standardPlan?.[`name_${i18n.language}`]} - {standardPrice}</p>
                </>
              )}
            </div> */}
          </div>
        </div>
        {/* <div className="plan-desc">
          <Heading id="plan-des-title" subtitle={t("PLANDESC")} />
          <p className="plan-description">
            {product[`plan_description_${i18n.language}`]}
          </p>
        </div> */}
      </div>
      <div className="bg-plan">
        <div
          className={`container-plan ${
            standardPlan && vipPlan ? "" : "single-plan"
          }`}
        >
          {standardPlan && !isVipProduct && (
            <div className="plan1">
              <Heading
                subtitle={standardPlan[`name_${i18n.language}`]}
                className="title-plan"
              />
              <ul className="list-plan">
                {Object.entries(
                  standardPlan[`description_${i18n.language}`] ?? {}
                ).map(([key, value]) => (
                  <li key={key}>
                    <div className="plan-item">
                      <div className="plan-description">{value}</div>
                      <div className="plan-check"></div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="plan-price">
                {/* <div className="price-title">{t("PRICE")}:</div> */}
                {isVipProduct && <span>{vipPrice}</span>}
                {isStandardProduct && <span>{standardPrice}</span>}
                {!isVipProduct && !isStandardProduct && (
                  <>
                    <span>{vipPrice}</span>
                    <span>{standardPrice}</span>
                  </>
                )}
              </div>
            </div>
          )}
          {vipPlan && !isStandardProduct && (
            <div className="plan2">
              <Heading
                subtitle={vipPlan[`name_${i18n.language}`]}
                className="title-plan"
              />
              <ul className="list-plan">
                {Object.entries(
                  vipPlan[`description_${i18n.language}`] ?? {}
                ).map(([key, value]) => (
                  <li key={key}>
                    <div className="plan-item">
                      <div className="plan-description">{value}</div>
                      <div className="plan-check">
                        {standardPlan &&
                          Object.values(
                            standardPlan[`description_${i18n.language}`] ?? {}
                          ).includes(value)}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="plan-price">
                {/* <div className="price-title">{t("PRICE")}:</div> */}
                {isVipProduct && <span>{vipPrice}</span>}
                {isStandardProduct && <span>{standardPrice}</span>}
                {!isVipProduct && !isStandardProduct && (
                  <>
                    <span>{vipPrice}</span>
                    <span>{standardPrice}</span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="table">{renderComparisonTable()}</div>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form className="contact-form">
          <div className="grid-container">
            <input type="text" placeholder={t("NAME")} name="name" />
            <input type="text" placeholder={t("Surname")} name="name" />
            <input type="email" placeholder={t("EMAIL")} name="email" />
            <input type="tel" placeholder={t("PHONE")} name="phone" />
            <input type="text" placeholder={t("Address")} name="address" />
            <input type="text" placeholder={t("Details")} name="country" />
            <input type="hidden" name="amount" value="500" />
          </div>
          <button type="submit">PAY</button>
        </form>
      </Modal>
      <Slider />
    </>
  );
};

export default SingleProduct;
